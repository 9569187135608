@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url(https://fonts.googleapis.com/css?family=Roboto:300);



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --color-primary: #191d2b;
  --color-secondary: #61dbfb;
  --color-white: #FFFFFF;
  --color-black: #000;
  --color-grey0: #f8f8f8;
  --color-grey-1: #dbe1e8;
  --color-grey-2: #b2becd;
  --color-grey-3: #6c7983;
  --color-grey-4: #454e56;
  --color-grey-5: #2a2e35;
  --color-grey-6: #12181b;
  --br-sm-2: 14px;
  --box-shadow-1: 0 3px 15px rgba(0, 0, 0, .3);
}

.light-mode {
  --color-primary: #FFFFFF;
  --color-secondary: #F56692;
  --color-white: #454e56;
  --color-black: #000;
  --color-grey0: #f8f8f8;
  --color-grey-1: #6c7983;
  --color-grey-2: #6c7983;
  --color-grey-3: #6c7983;
  --color-grey-4: #454e56;
  --color-grey-5: #f8f8f8;
  --color-grey-6: #12181b;
}

html {
  /* this makes sure the padding and the border is included in the box sizing */
  box-sizing: border-box;
  font-size: 16px;
  background-color: #222222;
}

html body {
  padding: 0;
  margin: 0;
  overflow-x: inherit;
}

body {
  min-height: 100vh;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
}

#root {
  flex-grow: 1;
  display: block;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #000;
}


/* Contact styles */
.contact-content-con {
  display: flex;
  padding-top: 3.5rem;
}

.contact-content-con .left-contact {
  flex: 2;
}

.contact-content-con .left-contact h4 {
  margin-top: 1rem;
  font-size: 2rem;
  text-transform: uppercase;
}

.contact-content-con .left-contact p {
  margin: 1rem 0;
  line-height: 2rem;
}

.contact-content-con .left-contact .contact-info .contact-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contact-content-con .left-contact .contact-info .contact-item p {
  margin: 0.3rem 0 !important;
  padding: 0 !important;
}

.contact-content-con .left-contact .contact-info .contact-item .icon {
  display: grid;
  grid-template-columns: 40px 1fr;
}

.contact-content-con .left-contact .contact-info .contact-item .icon i {
  display: flex;
  align-items: center;
  font-size: 1.3rem;
}

.contact-content-con .left-contact .contact-icon {
  display: flex;
  margin-top: 2rem;
}

.contact-content-con .left-contact .contact-icon a {
  display: flex;
  align-items: center;
  color: var(--color-white);
  background-color: var(--color-grey-5);
  cursor: pointer;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 0.4rem;
  transition: all 0.4s ease-in-out;
}

.contact-content-con .left-contact .contact-icon a:hover {
  background-color: var(--color-secondary);
}

.contact-content-con .left-contact .contact-icon a:hover i {
  color: var(--color-primary);
}

.contact-content-con .left-contact .contact-icon a i {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
}

.contact-content-con .right-contact {
  flex: 3;
  margin-left: 3rem;
}

.contact-content-con .right-contact .input-control {
  margin: 1.5rem 0;
}

.contact-content-con .right-contact .input-control input,
.contact-content-con .right-contact .input-control textarea {
  border-radius: 30px;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  padding: 0.8rem 1.1rem;
  outline: none;
  border: none;
  background-color: var(--color-grey-5);
  width: 100%;
  color: var(--color-white);
  resize: none;
}

.contact-content-con .right-contact .i-c-2 {
  display: flex;
}

.contact-content-con .right-contact .i-c-2 :last-child {
  margin-left: 1.5rem;
}

.contact-content-con .right-contact .submit-btn {
  display: flex;
  justify-content: flex-start;
}

#test {
  display: none;
}

.myDiv {
  color: white;
  text-decoration: none;
  border: none;
  background-color: #222222;
  margin-right: 20px;
  z-index: 10;
}




.page-item.active .page-link {
  color: #000 !important;
  background: #fff !important;
  border: 2px solid black !important;
}

.page-item .page-link {
  color: #000 !important;
  background: #fff !important;
}


/* Login/Registration styles */
.login-page {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
}

.form-login {
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

.form-login input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}

.form-login button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #f04365;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}

.form-login button:hover,
.form button:active,
.form button:focus {
  background: #005190;
}

.form-login .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}

.form-login .message a {
  color: #005190;
  text-decoration: none;
}

.form-login .register-form {
  display: none;
}

.layout-nav {
  border-right-color: rgba(255, 255, 255, 0.221);
  border-left-color: black;
  border-bottom-color: black;
  border-top-color: black;
  border-style: solid;
  border-width: thin;
  color: #fff;
  text-align: center;
}

.flex-column {
  color: #fff;
}

.layout-home {
  border-style: solid;
  border-width: thin;
  background-color: #000;
}

.products {
  background: black !important;
  color: black !important;


}


.small-dropdown {
  text-decoration: none !important;
  width: 50px;

}
.small-dropdown.show {
  text-decoration: none !important;

}
.nav-dropdowns {
  text-decoration: none !important;
  color: #ffffff !important;
}
.nav-area {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
}

.logo {
  text-decoration: none;
  font-size: 25px;
  color: inherit;
  margin-right: 20px;
}
.modal {
  z-index: 999;
}

.menus {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  text-decoration: none;
  justify-content: stretch;
  width: 100%;
}

.menu-items {
  position: relative;
  font-size: 14px;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  display: flex;
  align-items: baseline;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
}

button span {
  margin-left: 3px;
}

.menu-items > a, .menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}



.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
  color: #fff;
}

.dropdown {
  position: absolute;
  right: 0;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
  color: #000;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

/* content */

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 20px;
}

.content h2 {
  margin-bottom: 1rem;
}

.content a {
  color: #cc3852;
  margin-right: 10px;
}
.nav-dropdowns2 {
  text-decoration: none !important;
  color: #000 !important;
}
.nav-dropdowns3 {
  position: relative;
  left: 40px;
  text-decoration: none !important;
  color: #000 !important;
}

.theproducts {
  background: black !important;
  color: black !important;
}

.card-img-top {
  width: 100%;
  height: 15vw;
  object-fit: cover;
}


.card {
  background-color: #FFFFFF !important;
  color: gray;

}

.home-header {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 30px;
  font-size: x-small;
  padding: 0%;
}

.justify-content-center {

  color: white !important;
  font-size: medium;
}

.justify-content-end {
  font-size: small;
}

.col-1 .fa {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 35px;
  font-size: medium;

}

/* 
.row{
  border-width: thin;
  border-color: rgba(255, 255, 255, 0.221) !important;
  border-style: solid;
  padding-bottom: 0rem;
  padding-left: 0rem;
  padding-top: 0rem;
  padding-right: 0rem;
} */
.test2 {
  background-color: black;
  border: thin solid rgba(255, 255, 255, 0.221);
  margin-left: -1.15rem;
  margin-top: -.6rem;
  margin-bottom: -1.5rem;
  margin-right: -1.15rem;
  padding-left: 1rem;
  padding-top: .5rem;
  padding-right: 1rem;
  padding-bottom: .5rem;

}



.instructions {
  font-size: 0.75rem;
  border-radius: 0.5rem;
  background: #000;
  color: #fff;
  padding: 0.25rem;
  position: relative;
  bottom: -10px;
}

.instructions>svg {
  margin-right: 0.25rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  display: none;
}

.valid {
  color: limegreen;
  margin-left: 0.25rem;
}

.invalid {
  color: red;
  margin-left: 0.25rem;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.line {
  display: inline-block;
}

.flexGrow {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}


.persistCheck {
  font-size: 0.75rem;
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.persistCheck label {
  margin: 0;
}

[type="checkbox"] {
  height: 20px;
  width: 20px;
  margin: 0 5px 2px 2px;
}

::-webkit-scrollbar {
  width: 0px;
}

a {
  color: inherit;
  text-decoration: none;
}

.main-container {
  max-width: 1400px;
  margin: auto;
  width: 100%;

}

.layout {
  padding: 10px;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  margin: 6px 18px;
  position: relative;
}

.marquee-text {
  font-size: 29px;
  font-weight: 600;
  margin: 60px 0px;
  color: #f02d34;
}

.marquee {
  position: relative;
  height: 400px;
  width: 100%;
  overflow-x: hidden;
}

.track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 15s linear infinite;
  width: 180%;
}

.track:hover {
  animation-play-state: paused;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-50%);
  }
}

span.text-red {
  -webkit-text-stroke: 1px #f02d34;
  margin-left: 6px;
}

.logo {
  color: gray;
  font-size: 18px;

}

.nav-link.navMenu {
  color: lightgray !important;
}

.cart-icon {
  font-size: 25px;
  color: gray;
  cursor: pointer;
  position: relative;
  transition: transform .4s ease;
  border: none;
  background-color: transparent;
}

.cart-icon:hover {
  transform: scale(1.1, 1.1);
}

.cart-item-qty {
  position: absolute;
  right: -8px;
  font-size: 12px;
  color: #eee;
  background-color: #f02d34;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  text-align: center;
  font-weight: 600;

}

.item-button-group.navFilter {
  background-color: black;
}

.products-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
  width: 100%;
}

.product-card {
  cursor: pointer;
  transform: scale(1, 1);
  transition: transform 0.5s ease;
  color: #324d67;

}

.product-card:hover {
  transform: scale(1.1, 1.1)
}

.product-image {
  border-radius: 15px;
  background-color: #ebebeb;
  transform: scale(1, 1);
  transition: transform 0.5s ease;
}

.product-name {
  font-weight: 500;
}

.product-price {
  font-weight: 800;
  margin-top: 6px;
  color: black;
}

.items-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 40px;
  width: 100%;
}

.item-card {
  cursor: pointer;
  transform: scale(1, 1);
  transition: transform 0.5s ease;
  color: #324d67;
  margin-right: 20px;
  margin-left: 20px;

}

.item-image:hover {
  transform: scale(1.1, 1.1)
}

.item-image {
  border-radius: 15px;
  background-color: #ebebeb;
  transform: scale(1, 1);
  transition: transform 0.5s ease;
}

.text-display:hover {
  text-decoration: none;
  border: hidden;
  transform: scale(1.1, 1.1)
}
.text-display {
  height: 100%;
  width: 100%;
  text-decoration: none;
  border: hidden;
  transform: scale(1, 1);
  transition: transform 0.5s ease;
}
.item-button {
  height: 35px;
  width: 200px;
  font-size: medium;
  color: #f2f2f2;
  border-radius: 15px;
  background-color: blue;
  transform: scale(1, 1);
  transition: transform 0.5s ease;
}

.item-button-group {
  height: 35px;
  width: 35px;
  font-size: medium;
  color: #f2f2f2;
  border-radius: 15px;
  background-color: #dcdcdc;
  transform: scale(1, 1);
  transition: transform 0.5s ease;
}

.item-name {
  font-weight: 800;
}

.item-description {
  font-size: medium;
  color: black;
}


.texts-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
  width: 100%;
}

.text-card {
  cursor: text;
  color: #324d67;

}

.text-image {
  align-items: center;
  border-radius: 15px;
  background-color: #ebebeb;
}

.text-name {
  font-weight: 500;
  text-align: center;
}

.text-content {
  font-weight: 800;
  margin-top: 6px;
  color: black;
}

.hero-banner-container {
  padding: 100px 40px;
  background-color: #dcdcdc;
  border-radius: 15px;
  position: relative;
  height: 500px;
  line-height: 0.9;
  width: 100%;

}

.hero-banner-container .beats-solo {
  font-size: 20px;
}

.hero-banner-container button {
  border-radius: 15px;
  padding: 10px 16px;
  background-color: #f02d34;
  color: white;
  border: none;
  margin-top: 40px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  z-index: 10000 !important;
}

.hero-banner-container h3 {
  font-size: 4rem;
  margin-top: 4px;
}

.hero-banner-container h1 {
  color: white;
  font-size: 10em;
  margin-left: -20px;
  text-transform: uppercase;
}

.hero-banner-image {
  position: absolute;
  top: 0%;
  right: 20%;
  width: 450px;
  height: 450px;
}


.desc {
  position: absolute;
  right: 10%;
  bottom: 5%;
  width: 300px;
  line-height: 1.3;
  display: flex;
  flex-direction: column;
  color: #324d67;

}

.desc p {
  color: #5f5f5f;
  font-weight: 100;
  text-align: end;
}

.desc h5 {
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 16px;
  /* color: black; */
  align-self: flex-end;
}

.products-heading {
  text-align: center;
  margin: 40px 0px;
  color: #324d67;

}

.products-heading h2 {
  font-size: 40px;
  font-weight: 800;
}

.products-heading p {
  font-size: 16px;
  font-weight: 200;
}

.footer-banner-container {
  padding: 100px 40px;
  background-color: #f02d34;
  border-radius: 15px;
  position: relative;
  height: 400px;
  line-height: 1;
  color: white;
  width: 100%;
  margin-top: 120px;
}

.banner-desc {
  display: flex;
  justify-content: space-between;
}

.banner-desc button {
  border-radius: 15px;
  padding: 10px 16px;
  background-color: white;
  color: red;
  border: none;
  margin-top: 40px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
}

.banner-desc .left h3 {
  font-weight: 900;
  font-size: 80px;
  margin-left: 25px;
}

.banner-desc .left p {
  margin: 18px;
}

.footer-banner-image {
  position: absolute;
  /* top: -35%;
  left: 8%; */
  top: -25%;
  left: 25%;
}

.banner-desc .right {
  line-height: 1.4;
}

.banner-desc .right h3 {
  font-weight: 800;
  font-size: 60px;
}

.banner-desc .right p {
  font-size: 18px;
}

.banner-desc .right .company-desc {
  font-size: 14px;
  font-weight: 300;
}

.cart-wrapper {
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  /* will-change: transform; */
  transition: all 1s ease-in-out;

}

.cart-container {
  height: 100vh;
  width: 600px;
  background-color: white;
  float: right;
  padding: 40px 10px;
  position: relative;

}

.footer-container {
  color: #324d67;
  text-align: center;
  margin-top: 20px;
  padding: 30px 10px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: center;

}

.footer-container .icons {
  font-size: 30px;
  display: flex;
  gap: 10px;
}

.cart-heading {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  gap: 2px;
  margin-left: 10px;
  border: none;
  background-color: transparent;
}

.cart-heading .heading {
  margin-left: 10px;
}

.cart-num-items {
  margin-left: 10px;
  color: #f02d34;
}

.empty-cart {
  margin: 40px;
  text-align: center;
}

.empty-cart h3 {
  font-weight: 600;
  font-size: 20px;
}

.cancel {
  cursor: pointer;
}

.product-container {
  margin-top: 15px;
  overflow: auto;
  max-height: 70vh;
  padding: 20px 10px;
}

.product {
  display: flex;
  gap: 30px;
  padding: 20px;
}

.product .cart-product-image {
  width: 180px;
  height: 150px;
  border-radius: 15px;
  background-color: #ebebeb;
}

.item-desc .flex {
  display: flex;
  justify-content: space-between;
  width: 350px;
  color: #324d67;

}

.item-desc .bottom {
  margin-top: 60px;
}

.flex h5 {
  font-size: 24px;
}

.flex h4 {
  font-size: 20px;
}

.total {
  display: flex;
  justify-content: space-between;
}

.total h3 {
  font-size: 22px;

}

.remove-item {
  font-size: 24px;
  color: #f02d34;
  cursor: pointer;
  background: transparent;
  border: none;
}

.cart-bottom {
  position: absolute;
  bottom: 12px;
  right: 5px;
  width: 100%;
  padding: 30px 65px;

}

.btn-container {
  width: 400px;
  margin: auto;
}

.btn {
  width: 100%;
  max-width: 400px;
  padding: 10px 12px;
  border-radius: 15px;
  border: none;
  font-size: 20px;
  margin-top: 10px;
  margin-top: 40px;
  text-transform: uppercase;
  background-color: #f02d34;
  color: #fff;
  cursor: pointer;
  transform: scale(1, 1);
  transition: transform 0.5s ease;
}

.btn:hover {
  transform: scale(1.1, 1.1);
}

.product-detail-container {
  display: flex;
  gap: 40px;
  margin: 40px;
  margin-top: 60px;
  color: #324d67;

}

.product-detail-image {
  border-radius: 15px;
  background-color: #ebebeb;

  width: 400px;
  height: 400px;
  cursor: pointer;
  transition: .3s ease-in-out;
}

.product-detail-image:hover {
  background-color: #f02d34;

}

.small-images-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.small-image {
  border-radius: 8px;
  background-color: #ebebeb;
  width: 70px;
  height: 70px;
  cursor: pointer;
}

.selected-image {
  background-color: #f02d34;
}

.reviews {
  color: #f02d34;
  margin-top: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.product-detail-desc h4 {
  margin-top: 10px;
}

.product-detail-desc p {
  margin-top: 10px;
}

.reviews p {
  color: #324d67;
  margin-top: 0px;
}

.product-detail-desc .price {
  font-weight: 700;
  font-size: 26px;
  margin-top: 30px;
  color: #f02d34;
}

.price .old-price,
.product-price .old-price,
.price .old-price {
  color: gray;
  text-decoration: line-through;
}

.product-detail-desc .quantity {
  display: flex;
  gap: 20px;
  margin-top: 10px;
  align-items: center;
}

.product-detail-desc .buttons {
  display: flex;
  gap: 30px;
}

.buttons .add-to-cart {
  padding: 10px 20px;
  border: 1px solid #f02d34;
  margin-top: 40px;
  font-size: 18px;
  font-weight: 500;
  background-color: white;
  color: #f02d34;
  cursor: pointer;
  width: 200px;
  transform: scale(1, 1);
  transition: transform 0.5s ease;
}

.buttons .add-to-cart:hover {
  transform: scale(1.1, 1.1)
}

.buttons .buy-now {
  width: 200px;

  padding: 10px 20px;
  background-color: #f02d34;
  color: white;
  border: none;
  margin-top: 40px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transform: scale(1, 1);
  transition: transform 0.5s ease;
}

.buttons .buy-now:hover {
  transform: scale(1.1, 1.1)
}

.quantity-desc {
  border: 1px solid gray;
  padding: 6px;
}

.quantity-desc span {
  font-size: 16px;
  padding: 6px 12px;
  cursor: pointer;
}

.quantity-desc .minus {
  border-right: 1px solid gray;
  color: #f02d34;
}

.quantity-desc .num {
  border-right: 1px solid gray;
  font-size: 20px;
}

.quantity-desc .plus {
  color: rgb(49, 168, 49);

}

.maylike-products-wrapper {
  margin-top: 120px;
}

.maylike-products-wrapper h2 {
  text-align: center;
  margin: 50px;
  color: #324d67;

  font-size: 28px;
}

.maylike-products-container {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

.max-qty {
  font-weight: 500;
  color: #f02d34;
}

.success-wrapper,
.cancel-wrapper {
  background-color: white;
  min-height: 60vh;

}

.success,
.cancel {
  width: 1000px;
  margin: auto;
  margin-top: 160px;
  background-color: #dcdcdc;
  padding: 50px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.success .icon {
  color: green;
  font-size: 40px;
}

.success h2 {
  text-transform: capitalize;
  margin-top: 15px 0px;
  font-weight: 900;
  font-size: 40px;
  color: #324d67;
}

.success .email-msg {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.cancel p {
  font-size: 20px;
  font-weight: 600;
}

.success .description {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin: 10px;
  margin-top: 30px;
}

.success .description .email {
  margin-left: 5px;
  color: #f02d34;
}

.product-max-qty {
  margin-top: 10px;
}

@media screen and (max-width:800px) {
  .hero-banner-container {
    height: 560px;
  }

  .hero-banner-image {
    width: 77%;
    height: 62%;
    top: -2%;
    right: -6%;
  }

  .footer-banner-container {
    height: 560px;
    margin-top: 80px;
  }

  .footer-banner-image {
    width: 77%;
    left: 30%;
    top: 6%;
    height: 56%
  }

  .banner-desc .left h3 {
    font-weight: 900;
    font-size: 50px;
    margin-left: 5px;
  }

  .banner-desc .left p {
    margin: 18px;
  }

  .banner-desc .right h3 {
    font-size: 45px;
  }

  .banner-desc .right p {
    font-size: 18px;
  }

  .banner-desc .right .company-desc {
    font-size: 14px;
  }

  .banner-desc {
    flex-wrap: wrap;
    gap: 20px;
  }

  .hero-banner-container {
    line-height: 1.3;
  }

  .hero-banner-container h1 {
    font-size: 50px;
  }

  .hero-banner-container h3 {
    font-size: 40px;
  }

  .hero-banner-container button {
    margin-top: 90px;
    z-index: 10000;
  }

  .desc {
    bottom: 60px;
  }

  .product-detail-container {
    flex-wrap: wrap;
  }

  .product-detail-container .product-detail-image {
    width: 350px;
    height: 350px;
  }

  .cart-container {
    width: 415px;
    padding: 4px;
  }

  .cart-heading {
    margin-top: 35px;
  }

  .product-container {
    margin-top: 10px;
  }

  .product {
    padding: 20px 5px;

  }

  .product .cart-product-image {
    width: 25%;
    height: 25%;
  }

  .buttons .add-to-cart {
    width: 150px;
  }

  .buttons .buy-now {
    width: 150px;
  }

  .product-detail-container {
    margin: 20px;
  }

  .item-desc .flex {
    width: 200px;
  }

  .top {
    flex-wrap: wrap;
    gap: 10px;


  }

  .item-desc .bottom {
    margin-top: 30px;
  }

  .flex h5 {
    font-size: 16px;
    color: #324d67;
  }

  .flex h4 {
    font-size: 16px;
    color: black;
  }

  .cart-bottom {
    padding: 30px;
  }

  .total h3 {
    font-size: 20px;
  }

  .track {
    animation: marquee 10s linear infinite;
    width: 550%;
  }

  .success-wrapper,
  .cancel-wrapper {

    min-height: 69vh;
  }

  .success,
  .cancel {
    width: 370px;
    margin-top: 100px;
    padding: 20px;
  }

  .success {
    height: 350px;
  }

  .success h2 {
    font-size: 17px;
  }

  .btn-container {
    width: 300px;
    margin: auto;
  }

  .container {
    padding: 0 2rem;
  }

  .main {
    min-height: 100vh;
    padding: 4rem 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer {
    display: flex;
    flex: 1;
    padding: 2rem 0;
    border-top: 1px solid #eaeaea;
    justify-content: center;
    align-items: center;
  }

  .footer a {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }

  .title a {
    color: #0070f3;
    text-decoration: none;
  }

  .title a:hover,
  .title a:focus,
  .title a:active {
    text-decoration: underline;
  }

  .title {
    margin: 0;
    line-height: 1.15;
    font-size: 4rem;
  }

  .title,
  .description {
    text-align: center;
  }

  .description {
    margin: 4rem 0;
    line-height: 1.5;
    font-size: 1.5rem;
  }

  .code {
    background: #fafafa;
    border-radius: 5px;
    padding: 0.75rem;
    font-size: 1.1rem;
    font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
      Bitstream Vera Sans Mono, Courier New, monospace;
  }

  .grid {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 800px;
  }

  .card {
    margin: 1rem;
    padding: 1.5rem;
    text-align: left;
    color: inherit;
    text-decoration: none;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    transition: color 0.15s ease, border-color 0.15s ease;
    max-width: 300px;
  }

  .card:hover,
  .card:focus,
  .card:active {
    color: #0070f3;
    border-color: #0070f3;
  }

  .card h2 {
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
  }

  .card p {
    margin: 0;
    font-size: 1.25rem;
    line-height: 1.5;
  }

  .logo {
    height: 1em;
    margin-left: 0.5rem;
  }

  @media (max-width: 600px) {
    .grid {
      width: 100%;
      flex-direction: column;
    }
  }



}